

  export default {
    name: `AeqDASigner`,
    props: {
      label: {
        type: String,
        default: ``
      },
      daCode: {
        type: String,
        default: ``
      },
      value: {
        type: Object,
        default: undefined,
      },
      errorMessages: {
        type: Array,
        default: () => []
      }

    },
    data() {
      return {
        accept: false,
        acceptFinal: false,
        error: null,
        pdfUrl: null,
        step: 1,
        showModal: false,
        loading: false,
        filteredProps: {},
        signature: null,
        name: null,
        city: null,
        date: null,
        innerValue: this.initValue(this.value),
        buffer: null,
      }
    },
    computed: {
      da() {
        return this.$store.getters[`daSigner/declarations`] || []
      },
      isDisabled() {
        return this.filteredProps?.disabled || false
      },
      requirements() {
        return {extensions: this.$config.public.images_extensions}
      },
      traductedErrors() {
        return this.$helpers.wrapInArray(this.errorMessages).map((e) => this.$t(e))
      }
    },
    mounted() {
      const parentObject = { $props: {} }
      this.filteredProps = Object.assign(parentObject.$props, this.$attrs)
      this.filteredProps.label = this.label
    },
    methods: {
      clearValues() {
        this.signature = null
        this.name = null
        this.city = null
        this.date = null
        this.accept = false
        this.acceptFinal = false
        this.pdfUrl = null
        this.step = 1
        this.error = null
        this.buffer = null
        this.innerValue = this.initValue({})
        this.$emit(`input`, this.innerValue)
        this.$emit(`change`, this.innerValue)
      },
      initValue(value) {
        if (!!value) {
          if (!value.new) {
            value.new = []
          }
          if (!value.saved) {
            value.saved = []
          }
        } else {
          value = { new: [], saved: [] }
        }
        return JSON.parse(JSON.stringify(value))
      },
      getDocumentList() {
        if (this.innerValue.new.length === 0) {
          return []
        }
        return [
          {
            sourceIndex: 0,
            size: this.innerValue.new[0].size,
            fileName: this.innerValue.new[0].name,
            downloadLink: false,
            deleteLink: false,
            deletable: true,
            data: this.innerValue.new[0].content,
          }
        ]
      },
      async openModal() {
        this.clearValues();
        this.showModal = true;
        await this.$store.dispatch(`daSigner/getDeclarations`, this.daCode);

      },
      closeModal() {
        this.showModal = false;
        this.clearValues()
      },
      deleteDocument(doc) {
        this.clearValues()
      },
      onCloseModal(isOpen) {
        if (!isOpen) {
          this.clearValues();
        }
      },
      async nextStep() {
        if (this.step === 1) {
          const valid = await this.$refs?.acceptObs?.validate()
          if (valid) {
            this.step++;
          }
        }
        else if (this.step === 2) {
          const valid = await this?.$refs?.daSignerErrors?.validate()
          if (valid) {
            this.generateDA();
            this.step++;
          }
        }

      },
      prevStep() {
        if (this.step > 1) {
          this.step--;
        }
      },
      submit() {
        this.innerValue.new = this.buffer;
        this.$emit(`input`, this.innerValue)
        this.$emit(`change`, this.innerValue)
        this.showModal = false;
      },
      serializeDoc(blobContent) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({
              size: blobContent.size,
              name: `Declaration of Authorisation.pdf`,
              deletable: false,
              content_type: blobContent.type,
              content: reader.result,
            })
          };
          reader.readAsDataURL(blobContent);
        })
      },
      async generateDA() {

        this.pdfUrl = null;
        this.innerValue = this.initValue({});
        const data = {
          name: this.name,
          city: this.city,
          date: this.date,
          signature: this.signature,
          code: this.daCode,
          declarations: []
        }

        let response;
        try {
          response = await this.$store.dispatch(`daSigner/generateDA`, data)
        } catch (error) {
          this.error = error;
          this.step--;
          await this.$refs.daSignerErrors.setErrors(error.data)
          return;
        }

        const newBlob = new Blob([response], { type: `application/pdf` });
        this.pdfUrl = URL.createObjectURL(newBlob)
        this.buffer = [await this.serializeDoc(newBlob)];
        this.loading = false;
      },
    },
  }
